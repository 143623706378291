<template>
  <div class="not-found full-page">
    <h1>404 - Page Not Found</h1>
    <p>Oops! The page you are looking for does not exist.</p>
    <v-btn
      text
      depressed
      :color="colorBnn['color-bnn']"
      @click.stop="$router.push({ name: 'Campaign' })"
    >
      กลับสู่หน้าแรก
    </v-btn>
  </div>
</template>

<script>
import colorBnn from '@/config/color'

export default {
  name: '404',
  components: {},
  data() {
    return {
      colorBnn,
    }
  },
  mounted() {},

  computed: {},

  methods: {},
}
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

h1 {
  font-size: 36px;
  color: #333;
}

p {
  font-size: 18px;
  color: #666;
}
</style>
